import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Container,
  Row,
  Col,
  Alert,
  Table,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import SearchBar from "components/Bridgestone/SearchBar";
import Loader from "components/Bridgestone/Loader.jsx";
import { Parser } from "expr-eval";
import axios from "axios";
import uuidv4 from "uuid/v4";
import "assets/css/questions.css";
import baseURL from "variables/api";
import iconAttachment from "assets/img/icons/file.svg";
import imageCompression from "browser-image-compression";
import DialogAdvisorAlert from "../../components/Dialog/DialogAdvisorAlert";

let skipRendering = [];

const SALVAMENTO_AUTOMATICO_MS = 60000; // 1 minuto

class Template extends React.Component {
  fileInput = React.createRef();

  state = {
    showSection: [],
    error: false,
    errorText: "",
    formName: this.props.location.state.form.title,
    isEdit: this.props.location.state.isEdit,
    isAudit: this.props.location.state.isAudit,
    submitIdEdit: this.props.location.state.submitIdEdit,
    submitIdSave: this.props.location.state.submitIdSave,
    submitIdAudit: this.props.location.state.submitIdAudit,
    questionAnswers: [],
    stores: [],
    storesFiltered: [],
    form: this.props.location.state.form,
    originalForm: JSON.parse(JSON.stringify(this.props.location.state.form)),
    answers: this.props.location.state.answers || {},
    selectedClient: this.props.location.state.clientSelected,
    attachments: this.props.location.state._id
      ? this.props.location.state.attachments
      : {},
    showModalImage: {},
    isValid: false,
    clientValue: "",
    show: {},
    indexedDb: [],
    edit: false,
    id: this.props.location.state._id,
    rev: this.props.location.state._rev,
    fileNames: this.props.location.state._id
      ? this.props.location.state.fileNames
      : {},
    clientType: "Cliente",
    clientInfo: [],
    isSelfEvaluation: this.props.location.state.selfEval,
    selfStore: this.props.location.state.selfStore,
    selfClient: this.props.location.state.selfClient,
    selfUserId: this.props.location.state.selfUserId,
    fleets: [],
    fleetsFiltered: [],
    fleetsShow: false,
    psrVisible: false,
    psrInfo: [],
    address: {
      cep: this.props.location.state.answers
        ? this.props.location.state.answers["b2q1"]
        : "",
      street: this.props.location.state.answers
        ? this.props.location.state.answers["b2q2"]
        : "",
      number: this.props.location.state.answers
        ? this.props.location.state.answers["b2q3"]
        : "",
      compl: this.props.location.state.answers
        ? this.props.location.state.answers["b2q4"]
        : "",
      district: this.props.location.state.answers
        ? this.props.location.state.answers["b2q5"]
        : "",
      city: this.props.location.state.answers
        ? this.props.location.state.answers["b2q6"]
        : "",
      uf: this.props.location.state.answers
        ? this.props.location.state.answers["b2q7"]
        : "",
    },
    competitors: "",
    tooltipInfo: false,
    user: JSON.parse(localStorage.getItem("user")),
    formSaveInterval: null,
    showAdvisorSubmitAlert: false,
  };

  // Salva o questionário no banco de dados para preenchimento posterior
  async saveForm() {
    this.setState({ loading: true });

    let form = this.state.form;
    let answers = this.state.answers;
    let submit = {
      form: {
        metadata: {},
        title: form.title,
        category: form.category,
        version: form.version,
      },
      blocks: [],
      answers,
    };

    submit.form.metadata.datetime = form.metadata.datetime
      ? form.metadata.datetime
      : new Date();

    submit.form.metadata.startedAt = form.metadata.startedAt
      ? form.metadata.startedAt
      : "";

    submit.form.metadata.client = this.state.selectedClient || {
      name: "Cliente não especificado",
    };

    for (let block of form.blocks) {
      if (!block.hidden) {
        submit.blocks.push({
          id: block.id,
          answers: [],
        });
        
        for (let question of block.questions) {
          if (!question.hidden) {
            let ans = [];
            
            for (let key in answers) {
              if (key.startsWith(block.id + question.id))
                ans.push(answers[key]);
            }

            if (["attachment", "attachment-file", "options-multiple"].includes(question.type) && !ans.length) {
              submit.answers[`${block.id}${question.id}`] = [];
            }

            if (["options", "text", "textarea", "select", "number"].includes(question.type) && !ans.length) {
              submit.answers[`${block.id}${question.id}`] = "";
            }
          }
        }
      }
    }

    try {
      let data = new FormData();

      data.append("submit", JSON.stringify(submit));

      if (this.state.submitIdSave) {
        data.append("submitIdSave", this.state.submitIdSave);
      }

      for (let name in this.state.attachments) {
        if (!this.state.attachments[name].startsWith("https://")) {
          console.log("appending new image to form data");
          data.append(name, this.state.attachments[name]);
        }
      }

      let response = await axios.post(baseURL + "/submits/saved", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": `multipart/form-data;`,
          Authorization: "Bearer " + localStorage.getItem("banana"),
        },
      });

      const submitIdSave = response.data.submit._id;

      let blocksAnswered = [];
      let questionsAnswered = {};
      let attachments = {};

      let form = this.state.form;
      if (!form.metadata.startedAt) {
        form.metadata.startedAt = new Date(
          response.data.submit.form.metadata.startedAt
        );
      }

      let answers = response.data.submit.submit.answers;
      let attachmentQuestions = [];

      for (let bl of form.blocks) {
        for (let q of bl.questions) {
          if (answers[bl.id + q.id] || answers[bl.id + q.id] === "") {
            if (!blocksAnswered.includes(bl.id)) {
              blocksAnswered.push(bl.id);
            }
            if (questionsAnswered[bl.id] == undefined) {
              questionsAnswered[bl.id] = [];
            }
            questionsAnswered[bl.id].push(q.id);
          }

          if (q.type && q.type.startsWith("attachment"))
            attachmentQuestions.push(bl.id + q.id);
        }
      }

      for (let ind of attachmentQuestions) {
        let s3Base = `${baseURL}/uploads/`;
        if (Array.isArray(answers[ind])) {
          for (let file of answers[ind]) {
            attachments[file] = s3Base + file;
          }
        }
      }

      for (let block of form.blocks) {
        if (blocksAnswered.includes(block.id)) {
          block.hidden = false;
        }
        for (let question of block.questions) {
          if (
            questionsAnswered[block.id] != undefined &&
            questionsAnswered[block.id].includes(question.id)
          ) {
            question.hidden = false;
            let groupQuestions = block.questions.filter(
              (q) =>
                q.group &&
                q.loopGroup &&
                q.loopGroup.split(",").includes(question.id)
            );
            groupQuestions.map((q) => (q.hidden = false));
            //devemos mostrar o grupo aqui também
          }
        }
      }

      this.setState({
        form,
        attachments,
        answers,
        submitIdSave,
        loading: false,
      });
      this.showAlert("Salvo com sucesso!");
    } catch (err) {
      this.setState({ sending: false, success: false, loading: false });
      console.error(err);
      if (err.httpCode == 401) {
        localStorage.removeItem("banana");
        this.showAlert(err.message);
        setTimeout(() => {
          this.setState({ toLogin: true });
        }, 4000);
      } else {
        this.showAlert(err.message);
      }
    }
  }

  async componentDidMount() {
    // Busca as lojas que o usuário tem acesso
    try {
      const formCategory = this.state.form.category;
      const formTitle = this.state.form.title;
      const myClients = await axios.get(`${baseURL}/peb/my-clients?formTitle=${formTitle}&formCategory=${formCategory}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("banana"),
        },
      });

      const clients = myClients.data ? myClients.data : [];

      this.setState({ stores: clients, storesFiltered: clients });
    } catch (err) {
      console.log("Erro ao buscar clientes PEB >>>", err.message);
    }

    // Inicia a função para salvamento automatico do questionário a cada 1 minuto
    if (!this.state.isEdit && !this.state.isAudit) {
      const formSaveInterval = setInterval(
        () => this.saveForm(),
        SALVAMENTO_AUTOMATICO_MS
      );

      this.setState({ formSaveInterval });
    }

    // Prepara o questionário para renderização
    let blocksAnswered = [];
    let questionsAnswered = {};

    let names = [];
    let attachments = {};
    let clientType = this.state.clientType;
    let selectedClient = this.state.selectedClient;
    if (
      this.props.location.state.clientSelected &&
      this.props.location.state.clientSelected.type
    ) {
      clientType = this.props.location.state.clientSelected.type || "Cliente";
      this.setState({ clientType });
    } else if (this.state.form.category == "Engenharia de Vendas") {
      clientType = "Prospecção";
      selectedClient = {
        name: "",
        type: "Prospecção",
        address: {
          city: "",
          state: "",
        },
      };

      this.setState({ clientType, selectedClient });
    }

    let f = this.state.form;
    if (f.metadata == undefined) {
      f.metadata = {};
    }
    f.metadata.datetime = new Date();
    this.setState({ form: f });

    if (f.metadata && f.metadata.client && f.metadata.client.info) {
      this.setState({ clientInfo: f.metadata.client.info });
    }

    if (f.metadata && f.metadata.activity) {
      this.setState({ activity: f.metadata.activity });
    }

    if (Object.keys(this.state.answers).length > 0 && !this.state.id) {
      let form = this.state.form;
      let attachmentQuestions = [];

      for (let bl of form.blocks) {
        for (let q of bl.questions) {
          //console.log(q);
          if (this.state.answers[bl.id + q.id] || this.state.answers[bl.id + q.id] === "") {
            if (!blocksAnswered.includes(bl.id)) {
              blocksAnswered.push(bl.id);
            }
            if (questionsAnswered[bl.id] == undefined) {
              questionsAnswered[bl.id] = [];
            }
            questionsAnswered[bl.id].push(q.id);
          }

          if (q.type && q.type.startsWith("attachment"))
            attachmentQuestions.push(bl.id + q.id);
        }
      }
      //console.log(attachmentQuestions);

      for (let ind of attachmentQuestions) {
        let s3Base = `${baseURL}/uploads/`;
        if (Array.isArray(this.state.answers[ind])) {
          for (let file of this.state.answers[ind]) {
            attachments[file] = s3Base + file;
          }
        }
      }

      for (let block of form.blocks) {
        if (blocksAnswered.includes(block.id)) {
          block.hidden = false;
        }
        for (let question of block.questions) {
          if (
            questionsAnswered[block.id] != undefined &&
            questionsAnswered[block.id].includes(question.id)
          ) {
            question.hidden = false;
            let groupQuestions = block.questions.filter(
              (q) =>
                q.group &&
                q.loopGroup &&
                q.loopGroup.split(",").includes(question.id)
            );
            groupQuestions.map((q) => (q.hidden = false));
            //devemos mostrar o grupo aqui também
          }
        }
      }

      this.state.answers["b1q1b"] = this.props.location.state.answers["b1q1b"];
      this.setState({ form, attachments });
    }

    const showAdvisorSubmitAlert = localStorage.getItem("advisor-submit-alert");

    if (!showAdvisorSubmitAlert && f.category == "Programa de Excelência") {
      this.setState({ showAdvisorSubmitAlert: true });
    }
  }

  componentWillUnmount = () => {
    // Limpa o set interval para evitar vazamento de memória
    clearInterval(this.state.formSaveInterval);
  };

  getCompetitors = async () => {
    await this.setState({ loading: true });
    let competitors;

    try {
      competitors = (
        await axios.get(
          baseURL +
            `/competitors?name=${this.state.answers["b1q1a"]}&address=${this.state.answers["b2q2"]}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("banana"),
            },
          }
        )
      ).data;

      if (competitors.findAddressEqual.length > 0) {
        let code = competitors.findAddressEqual[0].code;

        this.setState({
          competitors: code,
        });
      }
    } catch (err) {
      localStorage.removeItem("banana");
      this.setState({ error: true, errorText: err.message });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
    await this.setState({ loading: false });
  };

  addAttachment = async (block, question, file, isLoop, loopIndex) => {
    var FR = new FileReader();

    FR.addEventListener("load", (e) => {
      let splitPoints = file.name.split(".");
      let extension = splitPoints[splitPoints.length - 1];
      let data = FR.result;

      let attachments = this.state.attachments;
      let name = uuidv4() + "." + extension;

      let fileNames = this.state.fileNames;
      fileNames[name] = file.name;
      attachments[name] = data;

      let answers = this.state.answers;

      if (answers[block.id + question.id] == undefined) {
        console.log("init nivel zero");
        answers[block.id + question.id] = [];
      } else {
        let last = answers[block.id + question.id].length - 1;
        console.log("NAO init nivel zero");
        if (isLoop && answers[block.id + question.id][last] == "") {
          answers[block.id + question.id].pop();
        }
        console.log(answers[block.id + question.id]);
      }

      if (isLoop) {
        if (answers[block.id + question.id][loopIndex] == undefined) {
          console.log("init loop " + loopIndex);
          answers[block.id + question.id][loopIndex] = [];
        } else {
          console.log("NAO init loop " + loopIndex);
          console.log(answers[block.id + question.id][loopIndex]);
        }
      }
      // if (
      //   answers[block.id + question.id].length > 2 &&
      //   this.state.form.title !== "Survey" &&
      //   this.state.form.title !== "Mapeamento de Fazendas"
      // ) {
      //   this.showAlert("Máximo de 2 anexos por pergunta.");
      //   return;
      // }
      let last;
      if (isLoop) {
        last = answers[block.id + question.id][loopIndex].length - 1;
      } else {
        last = answers[block.id + question.id].length - 1;
      }

      //verificar se não prejudica, TODO
      // se der zica, não adicionar "" no addField se for do tipo attachment
      if (isLoop) {
        // if (last >= 0) {
        //   answers[block.id + question.id][loopIndex][last] = name;
        // } else {
        answers[block.id + question.id][loopIndex].push(name);
        //}
      } else {
        // if (last >= 0) {
        //   answers[block.id + question.id][last] = name;
        // } else {
        answers[block.id + question.id].push(name);
        //}
      }

      console.log(answers, fileNames);

      this.setState({ answers, attachments, fileNames });
    });

    if (file && file.type.startsWith("image")) {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1280,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);

        if (compressedFile) {
          FR.readAsDataURL(compressedFile);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (file) {
      FR.readAsDataURL(file);
    }
  };

  showAlert(message) {
    this.setState({ error: true, errorText: message });
    setTimeout(() => {
      this.setState({ error: false });
    }, 3000);
  }

  handleOption = (event, option, question, block) => {
    let form = this.state.form;
    let answers = this.state.answers;
    let nexts = [];
    if (option.next) nexts = option.next.split(",");
    let reverts = [];
    if (option.revert) reverts = option.revert.split(",");

    if (event.target.type == "checkbox" && event.target.checked) reverts = [];
    if (event.target.type == "checkbox" && !event.target.checked) nexts = [];

    for (let next of nexts) {
      if (next.startsWith("b")) {
        let b = form.blocks.filter((el) => el.id == next)[0];
        b.hidden = false;
        //console.log(b);
      }
      if (next.startsWith("q")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //console.log(b);
        let q = b.questions.filter((el) => el.id == next)[0];
        // console.log(q);
        q.hidden = false;
      }
      if (next.startsWith("o")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //console.log(b);
        let q = b.questions.filter((el) => el.id == question.id)[0];
        //console.log(q);
        let o = q.options.filter((el) => el.id == next)[0];
        //console.log(o);
        o.hidden = false;
      }
      if (next.startsWith("g")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //console.log(b);
        let q = b.questions.filter((el) => el.id == next)[0];
        //console.log(q);
        q.hidden = false;

        let gEnd = b.questions.filter((el) => el.id == next + "e")[0];
        gEnd.hidden = false;

        // if (q.loopGroup) {
        //   for (let g of q.loopGroup.split(",")) {
        //     let qHidden = b.questions.filter((el) => el.id == g)[0];
        //     qHidden.hidden = false;
        //   }
        // }
      }
    }

    for (let revert of reverts) {
      if (revert.startsWith("b")) {
        let b = form.blocks.filter((el) => el.id == revert)[0];
        let originalB = this.state.originalForm.blocks.filter(
          (el) => el.id == revert
        )[0];

        b.hidden = true;
        for (let q of b.questions) {
          delete answers[b.id + q.id];
        }
        b.questions = JSON.parse(JSON.stringify(originalB.questions));
      }
      if (revert.startsWith("q")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        console.log(b);
        let q = b.questions.filter((el) => el.id == revert)[0];
        console.log(revert);
        console.log(q);
        //if (q.loop) {
        delete answers[b.id + q.id];
        //}
        q.hidden = true;

        // TODO devia ser recursivo e analisar todos os reverts da cadeia, seguindo aqui
        // os reverts de q. Ou o revert deve incluir a cadeia toda (ideal)
      }
      if (revert.startsWith("o")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //console.log(b);
        let q = b.questions.filter((el) => el.id == question.id)[0];
        //console.log(q);
        let o = q.options.filter((el) => el.id == revert)[0];
        //console.log(o);
        o.hidden = true;
      }
      if (revert.startsWith("g")) {
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //console.log(b);
        let group = b.questions.filter((el) => el.id == revert)[0];
        //console.log(q);
        group.hidden = true;

        let qEnd = b.questions.filter((el) => el.id == revert + "e")[0];
        qEnd.hidden = true;
        qEnd.hiddenRemove = true;

        if (group.loopGroup) {
          for (let g of group.loopGroup.split(",")) {
            let qHidden = b.questions.filter((el) => el.id == g)[0];
            qHidden.hidden = true;
            delete answers[b.id + qHidden.id];
          }
        }
      }
    }

    this.setState({ form, answers });
  };

  // for multiple fields, call addField X times for each question
  addField = (block, question, loopGroup) => {
    let answers = this.state.answers;
    let form = this.state.form;

    let ok = true;
    if (
      question.limit &&
      answers[block.id + question.id].length >= question.limit
    ) {
      ok = false;
    }
    if (ok) {
      if (loopGroup) {
        let group = loopGroup.split(",");

        for (let g of group) {
          if (answers[block.id + g] == undefined) {
            answers[block.id + g] = [];
          }
          answers[block.id + g].push("");

          let b = form.blocks.filter((el) => el.id == block.id)[0];
          let q = b.questions.filter((el) => el.id == g)[0];
          q.hidden = false;
        }
        let b = form.blocks.filter((el) => el.id == block.id)[0];
        //g1 e g1e
        let groupEnd = b.questions.filter(
          (el) => el.id == question.id + "e"
        )[0];
        groupEnd.hiddenRemove = false;
      } else {
        if (answers[block.id + question.id] == null) {
          answers[block.id + question.id] = [];
        }
        answers[block.id + question.id].push("");
      }
    }

    this.setState({ answers, form });
  };

  removeAnswer = (block, question) => {
    let questionAnswers = this.state.questionAnswers;
    let index = questionAnswers.findIndex(
      (el) => el.question.id == question.id && el.block.id == block.id
    );

    questionAnswers.splice(index, 1);

    this.setState({ questionAnswers });
  };

  validateForm = async () => {
    let form = this.state.form;
    let answers = this.state.answers;
    let pending = [];

    if (["Programa de Excelência", "Mystery Shopper"].includes(form.category)) {
      let client = this.state.selectedClient;

      if (this.state.isSelfEvaluation) {
        client = this.state.selfClient;
        this.setState({ selectedClient: client });
      }

      if (!client) {
        this.showAlert("Por favor, escolha o cliente.");
        return;
      }
    }

    for (let block of form.blocks) {
      if (!block.hidden) {
        for (let question of block.questions) {
          if (
            block.hidden != true &&
            question.hidden != true &&
            question.optional != true &&
            question.group != true &&
            question.endGroup != true &&
            question.type != "info"
          ) {
            let answer = answers[block.id + question.id];
            if (answer == undefined || answer == "" || answer == []) {
              pending.push(block.id + question.id);
            }
          }

          if (
            question.optional === true &&
            question.type === "options-multiple"
          ) {
            let answer = answers[block.id + question.id];
            if (!answer) {
              answers[block.id + question.id] = [];
            }
          }
        }
      } else {
        for (let question of block.questions) {
          if (
            block.hidden != true &&
            question.hidden != true &&
            question.optional != true &&
            question.group != true &&
            question.endGroup != true &&
            question.type != "info"
          ) {
            let answer = answers[block.id + question.id];
            if (answer == undefined || answer == "" || answer == []) {
              pending.push(block.id + question.id);
            }
          }

          if (
            question.optional === true &&
            question.type === "options-multiple"
          ) {
            let answer = answers[block.id + question.id];
            if (!answer) {
              answers[block.id + question.id] = [];
            }
          }
        }
      }
    }

    if (pending.length == 0) {
      this.setState({ answers, isValid: true });
      this.submit();
    } else {
      this.setState({ answers, isValid: false });

      let p = pending[pending.length - 1];
      let lastPending = this.state.form.blocks
        .filter((bl) => bl.id == p.split("q")[0])[0]
        .questions.filter((q) => q.id == "q" + p.split("q")[1])[0];

      this.showAlert(
        lastPending.text
          ? "Última pendência: " + lastPending.text
          : "Última pendência: " + lastPending.template.title
      );
    }
  };

  validateFormSave = () => {
    let form = this.state.form;
    let answers = this.state.answers;
    form.metadata.client = this.state.selectedClient || {
      name: "Cliente não especificado",
    };

    if (
      this.state.clientInfo != undefined &&
      this.state.clientInfo.length > 0
    ) {
      form.metadata.client.info = this.state.clientInfo;
    }

    this.insert(answers, form, this.state.attachments, this.state.fileNames);
  };

  displayOthers = (e, block, question, isLoop, answers, i) => {
    // answers["b1q1c"] = answers[block.id + question.id];

    if (isLoop) {
      answers[block.id + question.id][i] = e.target.value;
    } else {
      answers[block.id + question.id] = e.target.value;
    }
    this.setState({ answers });

    if (
      this.state.form.title === "Mapeamento da Concorrência - Consumer" ||
      this.state.form.title === "Mapeamento da Concorrência - TBR"
    ) {
      if (e.target.value === "Outros (especificar)" && question.id === "q7") {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q7a"
        );

        findQuestion[0].hidden = false;
      } else if (
        e.target.value !== "Outros (especificar)" &&
        question.id === "q7"
      ) {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q7a"
        );

        findQuestion[0].hidden = true;
      } else if (
        e.target.value === "Outros (especificar)" &&
        question.id === "q1f" &&
        (this.state.form.title === "Mapeamento da Concorrência - Consumer" ||
          this.state.form.title === "Mapeamento da Concorrência - TBR")
      ) {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q1c"
        );

        findQuestion[0].hidden = false;
      } else if (
        e.target.value !== "Outros (especificar)" &&
        question.id === "q1f"
      ) {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q1c"
        );

        findQuestion[0].hidden = true;
      }
    } else if (this.state.form.title === "Survey") {
      if (e.target.value.startsWith("Outro") && question.type === "select") {
        const qid = question.id.slice(0, question.id.length) + "a";
        let blockFound = this.state.form.blocks.find((b) => b.id === block.id);

        let findQuestion = blockFound.questions.find((q) => q.id === qid);

        if (!isLoop && findQuestion) findQuestion.hidden = false;
      } else if (
        !e.target.value.startsWith("Outro") &&
        question.type === "select" &&
        question.options.some((o) => o.startsWith("Outro"))
      ) {
        let blockFound = this.state.form.blocks.find((b) => b.id === block.id);

        let findQuestion = blockFound.questions.find(
          (q) => q.id === `${question.id}a`
        );

        if (!isLoop && findQuestion) findQuestion.hidden = true;
      }
    } else if (this.state.form.title === "Solicitação de Treinamento") {
      if (e.target.value === "Outro (especificar)" && question.id === "q2a") {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q2a1"
        );

        findQuestion[0].hidden = false;
      } else if (
        e.target.value !== "Outro (especificar)" &&
        question.id === "q2a"
      ) {
        let findQuestion = this.state.form.blocks[0].questions.filter(
          (question) => question.id === "q2a1"
        );

        findQuestion[0].hidden = true;
      }
    }
  };

  renderSimple = (index, block, question, aId) => {
    if (skipRendering.includes(question.id)) {
      //console.log("slip " + question.id);
      skipRendering.splice(skipRendering.indexOf(question.id), 1);
      return "";
    }
    return (
      <>
        <FormGroup key={index} className="mb-0">
          {question.superText && (
            <h4 className="heading-small mb-4">{question.superText}</h4>
          )}

          <h5 className="heading-small mb-4">
            {question.text}

            {question.info && (
              <>
                <span
                  id={`questionInfo-${index}`}
                  className="bg-primary text-white"
                  style={{
                    padding: "4px 9px",
                    fontSize: "12px",
                    borderRadius: "20px",
                    marginLeft: "12px",
                    cursor: "pointer",
                  }}
                >
                  ?
                </span>
                <Tooltip
                  placement="bottom"
                  target={`questionInfo-${index}`}
                  isOpen={this.state.tooltipInfo}
                  toggle={() =>
                    this.setState({
                      ...this.state,
                      tooltipInfo: !this.state.tooltipInfo,
                    })
                  }
                  style={{ maxWidth: "360px" }}
                >
                  <div
                    style={{ textAlign: "left" }}
                    dangerouslySetInnerHTML={{ __html: question.info }}
                  ></div>
                </Tooltip>
              </>
            )}
          </h5>

          {question.subText && (
            <label className="heading-small mb-4">{question.subText}</label>
          )}
          {question.examples && (
            <>
              <label className="heading-small ml-1 mb-4">Exemplos:</label>
              <Row>
                {question.examples.map((example, k) => (
                  <Col className="mb-2" sm="12" lg="6" xl="6" key={k}>
                    <img
                      src={require("assets/img/examples/" + example)}
                      style={{
                        position: "relative",
                        width: question.width || "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}

          <Row>
            <Col className="pl-2 mb-3" xs="12">
              {
                // bloco possivelmente obsoleto, sempre está sendo usado loop group com hide add
              }
              {question.loop && !question.hideAdd && (
                <Button
                  className="mb-2"
                  onClick={() => {
                    this.addField(block, question, question.loopGroup);
                  }}
                >
                  Adicionar +
                </Button>
              )}

              {this.buildInput(
                block,
                question,
                question.loop,
                question.hideAdd,
                aId
              )}
            </Col>
          </Row>
        </FormGroup>
      </>
    );
  };

  buildInput = (block, question, isLoop, hideAdd, loopIndex) => {
    let answers = this.state.answers;
    let aId = "";

    if (loopIndex != undefined) {
      aId = "_a" + loopIndex;
      isLoop = true;
    }

    let output = [];

    if (question.type === "text" || question.type === "textarea") {
      output.push(
        <React.Fragment key={loopIndex}>
          <div className="mb-2">
            <InputGroup className="pb-2 input-group">
              <Input
                type={question.type}
                rows="15"
                className="form-control"
                id={block.id + question.id + aId}
                onChange={(e) => {
                  if (isLoop) {
                    answers[block.id + question.id][loopIndex] = e.target.value;
                  } else {
                    answers[block.id + question.id] = e.target.value;
                  }

                  this.setState({ answers });
                  this.initStartedAt();
                }}
                value={
                  isLoop
                    ? answers[block.id + question.id]
                      ? answers[block.id + question.id][loopIndex]
                      : ""
                    : question.id === "q1d" &&
                      (this.state.form.title ===
                        "Mapeamento da Concorrência - Consumer" ||
                        this.state.form.title ===
                          "Mapeamento da Concorrência - TBR")
                    ? (answers["b1q1d"] = answers["b1q1a"])
                    : question.id === "q1g" &&
                      (this.state.form.title ===
                        "Mapeamento da Concorrência - Consumer" ||
                        this.state.form.title ===
                          "Mapeamento da Concorrência - TBR")
                    ? (answers["b1q1g"] = this.state.competitors)
                    : answers[block.id + question.id]
                }
                key={loopIndex}
                disabled={question.disabled === true ? true : false}
                maxlength={question.limit ? question.limit : ""}
              />
              {isLoop && !hideAdd && (
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => {
                      if (Array.isArray(answers[block.id + question.id])) {
                        answers[block.id + question.id].pop();
                      }

                      this.setState({ answers });
                    }}
                  >
                    X
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          </div>
        </React.Fragment>
      );
    } else if (question.type == "table") {
      let template = question.template;
      output.push(
        <React.Fragment key={loopIndex}>
          {isLoop && !hideAdd && (
            <InputGroupAddon addonType="append">
              <Button
                onClick={() => {
                  answers[block.id + question.id].pop();
                  this.setState({ answers });
                }}
              >
                X
              </Button>
            </InputGroupAddon>
          )}
          <Card className="p-0">
            <CardHeader
              style={{ height: "60px" }}
              className="border-0 bg-gradient-gray-dark pb-1 pt-1 text-white"
            >
              {template.title}
            </CardHeader>
            <CardBody className="pl-0 pr-0 tableFixHead">
              <Table responsive>
                <thead>
                  <tr>
                    {template.headers.map((column, hindex) => (
                      <th key={hindex} className="pl-2 pr-2">
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {template.body.map((line, lIndex) => {
                    return (
                      <tr key={lIndex}>
                        {line.map((cell, cellIndex) => {
                          let isSelect =
                            cell.replace("#", "").split("(")[0] == "select";

                          let options = null;

                          if (isSelect) {
                            let opts = cell
                              .replace("#", "")
                              .split("(")[1]
                              .replace(")", "")
                              .split(",");

                            // devemos selecionar a primeira resposta automatico
                            // caso contrario submete null, pois só grava a resposta no onChange
                            if (answers[block.id + question.id] == undefined) {
                              answers[block.id + question.id] = [];
                            }
                            if (
                              answers[block.id + question.id][lIndex] ==
                              undefined
                            ) {
                              answers[block.id + question.id][lIndex] = [];
                            }

                            if (
                              answers[block.id + question.id][lIndex][
                                cellIndex
                              ] === undefined
                            ) {
                              answers[block.id + question.id][lIndex][
                                cellIndex
                              ] = opts[0];
                            }

                            options = opts.map((opt, k) => {
                              if (opt != null)
                                return <option key={k}>{opt}</option>;
                            });
                          }

                          return cell.startsWith("#") ? (
                            <td
                              key={block.id + question.id + lIndex + cellIndex}
                              className="pl-2 pr-2"
                            >
                              <Input
                                type={cell.replace("#", "").split("(")[0]}
                                className="form-control"
                                style={{ minWidth: "80px" }}
                                id={
                                  block.id +
                                  question.id +
                                  "L" +
                                  lIndex +
                                  "C" +
                                  cellIndex
                                }
                                onChange={(e) => {
                                  let answers = this.state.answers;
                                  if (isLoop) {
                                    if (
                                      answers[block.id + question.id] ==
                                      undefined
                                    )
                                      answers[block.id + question.id] = [];
                                    if (
                                      answers[block.id + question.id][
                                        loopIndex
                                      ] == undefined
                                    ) {
                                      answers[block.id + question.id][
                                        loopIndex
                                      ] = [];
                                    }

                                    if (
                                      answers[block.id + question.id][
                                        loopIndex
                                      ][lIndex] == undefined
                                    ) {
                                      answers[block.id + question.id][
                                        loopIndex
                                      ][lIndex] = [];
                                    }
                                    if (e.target.value == "") {
                                      delete answers[block.id + question.id][
                                        loopIndex
                                      ][lIndex][cellIndex];
                                      let isEmpty = answers[
                                        block.id + question.id
                                      ][loopIndex][lIndex].every(
                                        (el) => el == null
                                      );
                                      // if (isEmpty) {
                                      //   answers[block.id + question.id][
                                      //     i
                                      //   ].splice(lIndex, 1);
                                      // }
                                    } else {
                                      answers[block.id + question.id][
                                        loopIndex
                                      ][lIndex][cellIndex] = e.target.value;
                                    }
                                  } else {
                                    if (
                                      answers[block.id + question.id] ==
                                      undefined
                                    ) {
                                      answers[block.id + question.id] = [];
                                    }
                                    if (
                                      answers[block.id + question.id][lIndex] ==
                                      undefined
                                    ) {
                                      answers[block.id + question.id][lIndex] =
                                        [];
                                    }
                                    if (e.target.value == "") {
                                      delete answers[block.id + question.id][
                                        lIndex
                                      ][cellIndex];
                                      let isEmpty = answers[
                                        block.id + question.id
                                      ][lIndex].every((el) => el == null);
                                      // if (isEmpty) {
                                      //   answers[
                                      //     block.id + question.id
                                      //   ].splice(lIndex, 1);
                                      // }
                                    } else {
                                      answers[block.id + question.id][lIndex][
                                        cellIndex
                                      ] = e.target.value;
                                    }
                                  }
                                  this.setState({
                                    answers,
                                  });
                                  this.initStartedAt();
                                }}
                                key={loopIndex}
                                value={
                                  isLoop
                                    ? answers[block.id + question.id][loopIndex]
                                      ? answers[block.id + question.id][
                                          loopIndex
                                        ][lIndex]
                                        ? answers[block.id + question.id][
                                            loopIndex
                                          ][lIndex][cellIndex]
                                        : ""
                                      : ""
                                    : answers[block.id + question.id]
                                    ? answers[block.id + question.id][lIndex]
                                      ? answers[block.id + question.id][lIndex][
                                          cellIndex
                                        ]
                                      : ""
                                    : ""
                                }
                              >
                                {options}
                              </Input>
                            </td>
                          ) : (
                            <td
                              className="pl-2 pr-2"
                              key={block.id + question.id + lIndex + cellIndex}
                            >
                              {cell}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </React.Fragment>
      );
    } else if (question.type == "number")
      output.push(
        <React.Fragment key={loopIndex}>
          <div className="mb-2">
            <InputGroup className="pb-2 input-group">
              <Input
                type="number"
                className="form-control form-control"
                id={block.id + question.id + aId}
                max={question.max ? question.max : undefined}
                min={question.min ? question.min : undefined}
                onChange={(e) => {
                  let ok = true;
                  if (question.max) {
                    if (e.target.value > question.max) ok = false;
                  }
                  if (question.min) {
                    if (e.target.value < question.min) ok = false;
                  }
                  if (isLoop) {
                    if (ok || e.target.value == "")
                      answers[block.id + question.id][loopIndex] =
                        e.target.value;
                  } else {
                    if (ok || e.target.value == "")
                      answers[block.id + question.id] = e.target.value;
                  }

                  this.setState({ answers });
                  this.initStartedAt();
                }}
                value={
                  isLoop
                    ? answers[block.id + question.id]
                      ? answers[block.id + question.id][loopIndex]
                      : ""
                    : answers[block.id + question.id]
                }
                key={loopIndex}
              />
              {isLoop && !hideAdd && (
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => {
                      answers[block.id + question.id].pop();
                      this.setState({ answers });
                    }}
                  >
                    X
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          </div>
        </React.Fragment>
      );
    else if (question.type == "info") {
      let val;
      if (question.content.startsWith("=")) {
        let formula = question.content.split("=")[1];

        formula = formula.replace(/#/g, "");
        //console.log(formula);
        let parser = new Parser();
        let expression = parser.parse(formula);
        let blockId = block.id;
        let keys = formula.match(/(q)\w+/g);
        let divisor = formula.match(/N/)
          ? formula.match(/N/).length > 0
          : false;
        //console.log(keys);
        let variables = {};
        let all = {};
        for (let k of keys) {
          if (k.includes("L")) {
            let row = k.split("L")[1].split("C")[0];
            let col = k.split("L")[1].split("C")[1];
            let answerIndex = k.split("L")[0];
            let v = answers[blockId + answerIndex]
              ? answers[blockId + answerIndex][row]
                ? answers[blockId + answerIndex][row][col]
                : undefined
              : undefined;
            //if (!v) v = 0;
            if (v != undefined) {
              variables[k] = v;
              all[k] = v;
            } else {
              v = 0;
              all[k] = v;
            }
          } else {
            let v = answers[blockId + k];
            if (!v) v = 0;
            variables[k] = v;
            all[k] = v;
          }
        }

        if (divisor) {
          let len = Object.keys(variables).length;
          variables["N"] = len;
          all["N"] = len;
        }

        // console.log(variables);
        // console.log(divisor);
        let minLen = 1;
        if (divisor) {
          minLen++;
        }
        if (Object.keys(variables).length > minLen) {
          //console.log(Object.keys(variables).length + "maior que" + minLen);
          //console.log(variables);
          val = expression.evaluate(all);
        }

        if (isNaN(val)) val = 0;
      } else {
        val = question.content;
      }
      // answers[block.id + question.id] = val;
      // this.setState({ answers });
      output.push(
        <React.Fragment key={loopIndex}>
          <div>
            <input
              type="text"
              disabled={true}
              className="form-control form-control "
              id={block.id + question.id}
              value={val}
            />
          </div>
        </React.Fragment>
      );
    } else if (question.type == "options")
      output.push(
        <React.Fragment key={loopIndex}>
          <Row>
            {question.options.map(
              (option, k) =>
                !option.hidden && (
                  <React.Fragment key={k}>
                    <Col
                      sm="12"
                      lg={option.example && !option.fullColumn ? "6" : "12"}
                      xl={option.example && !option.fullColumn ? "6" : "12"}
                    >
                      <div className="custom-control custom-radio mb-4">
                        <input
                          name={"radio-" + block.id + question.id}
                          className="custom-control-input"
                          id={block.id + question.id + option.id + aId}
                          type="radio"
                          onChange={(e) => {
                            this.handleOption(e, option, question, block);
                            answers[block.id + question.id] = option.text;

                            this.setState({ answers });
                            this.initStartedAt();
                          }}
                          checked={
                            answers[block.id + question.id] == option.text
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={block.id + question.id + option.id}
                        >
                          {option.text}
                        </label>
                      </div>
                      {option.example && (
                        <div key={"ex" + k}>
                          <img
                            src={require("assets/img/examples/" +
                              option.example)}
                            style={{
                              position: "relative",
                              width: question.width || "100%",
                              maxWidth: "100%",
                            }}
                            alt=" "
                          />
                          <hr />
                        </div>
                      )}
                    </Col>
                  </React.Fragment>
                )
            )}
          </Row>
        </React.Fragment>
      );
    else if (question.type == "select")
      output.push(
        <React.Fragment key={loopIndex}>
          <Input
            type="select"
            onChange={(e) => {
              // this.displayOthers(
              //   e,
              //   block,
              //   question,
              //   isLoop,
              //   answers,
              //   loopIndex
              // );
              let option = question.options.find((o) => o.text === e.target.value);
              option = !option ? {} : option;
              
              this.handleOption(e, option, question, block);
              answers[block.id + question.id] = option.text;

              this.setState({ answers });
              this.initStartedAt();
            }}
            value={
              isLoop
                ? this.state.answers[block.id + question.id][loopIndex]
                  ? answers[block.id + question.id][loopIndex]
                  : ""
                : answers[block.id + question.id]
            }
          >
            <option
              value=""
              disabled={question.optional && question.optional === true ? false : true}
            >
              Selecione...
            </option>
            {question.options.map((option, k) => (
              <option key={k}>{option.text}</option>
            ))}
          </Input>
        </React.Fragment>
      );
    else if (question.type == "options-multiple")
      output.push(
        <React.Fragment key={loopIndex}>
          <Row>
            {question.options.map(
              (option, k) =>
                !option.hidden && (
                  <React.Fragment key={k}>
                    <Col
                      sm="12"
                      lg={option.example && !option.fullColumn ? "6" : "12"}
                      xl={option.example && !option.fullColumn ? "6" : "12"}
                    >
                      <div
                        className="custom-control custom-control-alternative custom-checkbox mb-3"
                        key={k}
                      >
                        <input
                          className="custom-control-input"
                          id={block.id + question.id + option.id + aId}
                          type="checkbox"
                          onChange={(e) => {
                            this.handleOption(e, option, question, block);
                            if (answers[block.id + question.id] == undefined)
                              answers[block.id + question.id] = [];

                            if (e.target.checked) {
                              answers[block.id + question.id].push(option.text);
                            } else {
                              answers[block.id + question.id].splice(
                                answers[block.id + question.id].indexOf(
                                  option.text
                                ),
                                1
                              );
                            }

                            this.setState({ answers });
                            this.initStartedAt();
                          }}
                          checked={
                            answers[block.id + question.id] &&
                            answers[block.id + question.id].includes(
                              option.text
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={block.id + question.id + option.id}
                        >
                          {option.text}
                        </label>
                      </div>
                      {option.example && (
                        <div key={"ex" + k}>
                          <img
                            src={require("assets/img/examples/" +
                              option.example)}
                            style={{
                              position: "relative",
                              width: question.width || "100%",
                              maxWidth: "100%",
                            }}
                            alt=" "
                          />
                          <hr />
                        </div>
                      )}
                    </Col>
                  </React.Fragment>
                )
            )}
          </Row>
        </React.Fragment>
      );
    else if (question.type == "datetime")
      output.push(
        <React.Fragment key={loopIndex}>
          <div className="form-group">
            <label>Selecione a Data:</label>

            <Input
              type="date"
              className="form-control"
              onChange={(e) => {
                if (isLoop) {
                  answers[block.id + question.id][loopIndex] = e.target.value;
                } else {
                  answers[block.id + question.id] = e.target.value;
                }

                this.setState({ answers });
                this.initStartedAt();
              }}
              value={
                isLoop
                  ? answers[block.id + question.id]
                    ? answers[block.id + question.id][loopIndex]
                    : ""
                  : answers[block.id + question.id]
              }
            />
          </div>
        </React.Fragment>
      );
    else if (question.type == "time")
      output.push(
        <React.Fragment key={loopIndex}>
          <div className="form-group">
            <Input
              type="time"
              className="form-control"
              onChange={(e) => {
                answers[block.id + question.id] = e.target.value;
                this.initStartedAt();
              }}
              value={answers[block.id + question.id]}
            />
          </div>
        </React.Fragment>
      );
    else if (question.type == "attachment") {
      let loadedAttachments = [];
      let thisAnswer;

      if (isLoop && loopIndex != undefined) {
        thisAnswer = this.state.answers[block.id + question.id][loopIndex];
      } else {
        //console.log("NOT LOOP");
        thisAnswer = this.state.answers[block.id + question.id];
        if (thisAnswer == undefined) {
          thisAnswer = [];
        }
      }

      for (let link of thisAnswer) {
        loadedAttachments.push(
          <>
            <div className="mb-2">
              <React.Fragment key={loopIndex}>
                <img
                  src={
                    link.endsWith(".jpg") ||
                    link.endsWith(".png") ||
                    link.endsWith(".jpeg")
                      ? this.state.attachments[link]
                      : !link
                      ? null
                      : iconAttachment
                  }
                  onClick={() => {
                    let showModalImage = this.state.showModalImage;
                    showModalImage[link] = true;
                    this.setState({
                      showModalImage,
                    });
                  }}
                  className="mb-2 attachment-form"
                />
                <br />
                <label>
                  {this.state.fileNames[link] ? (
                    this.state.fileNames[link]
                  ) : (
                    <a href={`${baseURL}/uploads/` + link}>{link}</a>
                  )}
                </label>
                <hr />
              </React.Fragment>
            </div>
            {thisAnswer.map((name, index) => (
              <Modal
                toggle={() => {
                  let showModalImage = this.state.showModalImage;
                  showModalImage[name] = !this.state.showModalImage[name];
                  this.setState({
                    showModalImage,
                  });
                }}
                isOpen={this.state.showModalImage[name]}
                key={index}
              >
                <ModalBody>
                  <img
                    src={
                      name.endsWith("jpg") ||
                      name.endsWith("jpeg") ||
                      name.endsWith("png")
                        ? this.state.attachments[name]
                        : iconAttachment
                    }
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "inline-block",
                    }}
                  />
                  <label>{this.state.fileNames[name]}</label>
                </ModalBody>

                <ModalFooter>
                  <Button
                    default
                    onClick={(e) => {
                      let attachments = this.state.attachments;
                      delete attachments[name];
                      let answers = this.state.answers;
                      thisAnswer.splice(index, 1);
                      if (isLoop) {
                        answers[block.id + question.id][loopIndex] = thisAnswer;
                      } else {
                        answers[block.id + question.id] = thisAnswer;
                      }

                      let fileNames = this.state.fileNames;
                      delete fileNames[name];
                      let showModalImage = this.state.showModalImage;
                      showModalImage[name] = false;
                      this.setState({
                        showModalImage,
                        answers,
                        attachments,
                        fileNames,
                      });
                    }}
                  >
                    Excluir
                  </Button>
                </ModalFooter>
              </Modal>
            ))}
          </>
        );
      }

      output.push(
        <React.Fragment key={loopIndex}>
          <label className="fileContainer round btn btn-primary">
            <i className="ni ni-camera-compact m-0" />
            <Input
              placeholder="Tirar uma foto"
              type="file"
              accept="image/*"
              capture
              onChange={(e) => {
                this.addAttachment(
                  block,
                  question,
                  e.target.files[0],
                  isLoop,
                  loopIndex
                );
              }}
            />
          </label>
          <label className="fileContainer btn btn-primary">
            Selecionar Arquivo
            <Input
              placeholder=""
              type="file"
              accept="image/*"
              onChange={(e) => {
                this.addAttachment(
                  block,
                  question,
                  e.target.files[0],
                  isLoop,
                  loopIndex
                );
                this.initStartedAt();
              }}
            />
          </label>
          {loadedAttachments}
        </React.Fragment>
      );
    } else if (question.type == "attachment-file") {
      let loadedAttachments = [];
      let thisAnswer;

      if (isLoop && loopIndex != undefined) {
        if (this.state.answers[block.id + question.id] != undefined) {
          thisAnswer = this.state.answers[block.id + question.id][loopIndex];
        } else {
          thisAnswer = [];
        }
      } else {
        //console.log("NOT LOOP");
        thisAnswer = this.state.answers[block.id + question.id];
        if (thisAnswer == undefined) {
          thisAnswer = [];
        }
      }

      for (let link of thisAnswer) {
        loadedAttachments.push(
          <>
            <div className="mb-2">
              <React.Fragment key={loopIndex}>
                <img
                  src={
                    link.endsWith(".jpg") ||
                    link.endsWith(".png") ||
                    link.endsWith(".jpeg")
                      ? this.state.attachments[link]
                      : !link
                      ? null
                      : iconAttachment
                  }
                  onClick={() => {
                    let showModalImage = this.state.showModalImage;
                    showModalImage[link] = true;
                    this.setState({
                      showModalImage,
                    });
                  }}
                  className="mb-2 attachment-form"
                />
                <br />
                <label>
                  {this.state.fileNames[link] ? (
                    this.state.fileNames[link]
                  ) : (
                    <a href={`${baseURL}/uploads/` + link}>{link}</a>
                  )}
                </label>
                <hr />
              </React.Fragment>
            </div>
            {thisAnswer.map((name, index) => (
              <Modal
                toggle={() => {
                  let showModalImage = this.state.showModalImage;
                  showModalImage[name] = !this.state.showModalImage[name];
                  this.setState({
                    showModalImage,
                  });
                }}
                isOpen={this.state.showModalImage[name]}
                key={index}
              >
                <ModalBody>
                  <img
                    src={
                      name.endsWith("jpg") ||
                      name.endsWith("jpeg") ||
                      name.endsWith("png")
                        ? this.state.attachments[name]
                        : iconAttachment
                    }
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "inline-block",
                    }}
                  />
                  <label>{this.state.fileNames[name]}</label>
                </ModalBody>

                <ModalFooter>
                  <Button
                    default
                    onClick={(e) => {
                      let attachments = this.state.attachments;
                      delete attachments[name];
                      let answers = this.state.answers;
                      thisAnswer.splice(index, 1);
                      if (isLoop) {
                        answers[block.id + question.id][loopIndex] = thisAnswer;
                      } else {
                        answers[block.id + question.id] = thisAnswer;
                      }

                      let fileNames = this.state.fileNames;
                      delete fileNames[name];
                      let showModalImage = this.state.showModalImage;
                      showModalImage[name] = false;
                      this.setState({
                        showModalImage,
                        answers,
                        attachments,
                        fileNames,
                      });
                    }}
                  >
                    Excluir
                  </Button>
                </ModalFooter>
              </Modal>
            ))}
          </>
        );
      }

      output.push(
        <React.Fragment key={loopIndex}>
          <label className="fileContainer btn btn-primary">
            Selecionar Arquivo
            <Input
              placeholder=""
              type="file"
              onChange={(e) => {
                this.addAttachment(
                  block,
                  question,
                  e.target.files[0],
                  isLoop,
                  loopIndex
                );
                this.initStartedAt();
              }}
            />
          </label>
          {loadedAttachments}
        </React.Fragment>
      );
    }

    return output.length > 0 ? output.map((el) => el.props.children) : <></>;
  };

  initStartedAt = async () => {
    const f = this.state.form;

    if (f.metadata && !f.metadata.startedAt) {
      try {
        const result = await axios.get(`${baseURL}/submits/datetime`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("banana"),
          },
        });
  
        f.metadata.startedAt = new Date(result.data.datetime);
  
        this.setState({ form: f });
      } catch (e) {}
    }
  }

  submit = async () => {
    let form = this.state.form;
    let answers = this.state.answers;
    let submit = {
      form: {
        id: form.id,
        metadata: {},
        title: form.title,
        category: form.category,
        version: form.version,
      },
      blocks: [],
    };

    if (
      form.title === "Mapeamento da Concorrência - TBR" ||
      form.title === "Mapeamento da Concorrência - Consumer"
    ) {
      if (answers["b1q1c"]) {
        submit.form.metadata.client = {
          name: answers["b1q1c"],
        };
      } else {
        submit.form.metadata.client = {
          name: answers["b1q1a"],
        };
      }

      if (answers["b1q2"] === "Sim") {
        answers["b2q1"] = this.state.address.cep;
        answers["b2q2"] = this.state.address.street;
        answers["b2q3"] = this.state.address.number;
        answers["b2q4"] = this.state.address.compl;
        answers["b2q5"] = this.state.address.district;
        answers["b2q6"] = this.state.address.city;
        answers["b2q7"] = this.state.address.uf;
      }
    } else {
      submit.form.metadata.client = this.state.selectedClient || {
        name: "Cliente não especificado",
      };
    }

    if (
      (this.state.form.title === "Shopping de Preços - TBR" ||
        this.state.form.title === "Shopping de Preços - PCT") &&
      this.state.form.category === "TBR Revendas"
    ) {
      submit.form.metadata.fleet = this.state.selectedFleet || {
        name: "Frota não especificada",
      };
    }

    if (this.state.isSelfEvaluation) {
      submit.form.metadata.client = this.state.selfClient;
    }
    if (
      this.state.clientInfo != undefined &&
      this.state.clientInfo.length > 0
    ) {
      submit.form.metadata.client.info = this.state.clientInfo;
    }

    submit.form.metadata.datetime = form.metadata.datetime
      ? form.metadata.datetime
      : new Date();

    submit.form.metadata.startedAt = form.metadata.startedAt
      ? form.metadata.startedAt
      : "";

    submit.answers = answers;

    if (this.state.activity && this.state.clientType == "Outros") {
      submit.form.metadata.activity = this.state.activity;
    }

    for (let block of form.blocks) {
      if (!block.hidden) {
        submit.blocks.push({
          id: block.id,
          answers: [],
        });
        
        for (let question of block.questions) {
          if (!question.hidden) {
            let ans = [];

            for (let key in answers) {
              if (key.startsWith(block.id + question.id))
                ans.push(answers[key]);
            }

            if (["attachment", "attachment-file", "options-multiple"].includes(question.type) && !ans.length) {
              submit.answers[`${block.id}${question.id}`] = [];
            }

            if (["options", "text", "textarea", "select", "number"].includes(question.type) && !ans.length) {
              submit.answers[`${block.id}${question.id}`] = "";
            }
          }
        }
      }
    }

    this.setState({ submitReady: true, submit });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    console.log(suggestion);
    this.setState({ selectedClient: suggestion });
  };

  getFleets = async () => {
    let fleets;

    try {
      fleets = (
        await axios.get(baseURL + "/forms/fleets", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("banana"),
          },
        })
      ).data;

      console.log(fleets);

      this.setState({
        fleets: fleets.data,
        fleetsFiltered: fleets.data,
      });
    } catch (error) {
      this.setState({ error: true, errorText: error.message });
      setTimeout(() => {
        this.setState({ error: false, toLogin: true });
      }, 4000);
    }
  };

  handleSearchFleet = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    console.log(suggestion);
    this.setState({ selectedFleet: suggestion });
  };

  render() {
    if (this.state.toLogin) {
      this.setState({ toLogin: false });
      return (
        <Redirect
          push
          to={{
            pathname: "/auth/login/",
          }}
        />
      );
    }

    if (this.state.submitReady) {
      let path = "/admin/submit";

      this.setState({ submitReady: false });

      return (
        <Redirect
          push
          to={{
            pathname: path,
            state: {
              submit: this.state.submit,
              attachments: this.state.attachments,
              isSelfEval: this.state.isSelfEvaluation,
              selfUserId: this.state.selfUserId,
              isEdit: this.state.isEdit,
              isAudit: this.state.isAudit,
              submitIdEdit: this.state.submitIdEdit,
              submitIdSave: this.state.submitIdSave,
              submitIdAudit: this.state.submitIdAudit,
            },
          }}
        />
      );
    }

    return (
      <>
        {/* Page content */}
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert
            color="danger"
            style={{ position: "fixed", top: "30px", zIndex: 9999 }}
          >
            {this.state.errorText}
          </Alert>
        ) : (
          ""
        )}

        <DialogAdvisorAlert
          isOpen={this.state.showAdvisorSubmitAlert}
          setClosedDialog={(showAgain) => {
            this.setState({ showAdvisorSubmitAlert: false });
            if (showAgain) {
              localStorage.setItem("advisor-submit-alert", true);
            }
          }}
        />

        <div className="pb-3 pt-2 pt-md-3">
          {this.state.showValidation ? (
            <Alert
              color="danger"
              style={{ position: "fixed", top: "30px", zIndex: 9999 }}
            >
              Por favor, responda todas as perguntas antes de enviar.
            </Alert>
          ) : (
            ""
          )}

          <Container className="mt-2 mt-md-5 mb-2" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="background-primary shadow m-1 card-full">
                  <CardHeader className="background-primary border-0 pb-2 pt-2 card-header">
                    <Row className="align-items-center">
                      <Col xs="8" className="p-0">
                        <h3 className="mb-0 pl-2 text-white font-weight-normal">
                          {this.state.form.title} - Informações Gerais
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="bg-white">
                    <Form>
                      <FormGroup>
                        <Row>
                          <Col className="order-xl-1" xl="12">
                            <FormGroup>
                              <Label>Data de Referência</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="m-1" xs="12">
                            <Input
                              type="date"
                              onChange={(e) => {
                                let form = this.state.form;
                                form.metadata.datetime = new Date(
                                  `${e.target.value}T03:00:00Z`
                                );
                                this.setState({ form });
                              }}
                              value={
                                this.state.form.metadata &&
                                this.state.form.metadata.datetime &&
                                this.state.form.metadata.datetime.toISOString !=
                                  null
                                  ? this.state.form.metadata.datetime
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="order-xl-1 mt-3" xl="12">
                            <FormGroup>
                              <Label>Dados do Cliente</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        {this.state.clientType == "Cliente" && (
                          <Row>
                            <Col className="m-1" xs="12">
                              <SearchBar
                                suggestions={this.state.stores}
                                suggestionsFiltered={this.state.storesFiltered}
                                onSuggestionSelected={this.onSuggestionSelected}
                                value={
                                  this.state.selectedClient &&
                                  this.state.selectedClient.id
                                    ? this.state.selectedClient
                                    : ""
                                }
                              />
                            </Col>
                          </Row>
                        )}
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.state.form.blocks.map((block, blockIndex) => (
              <Row key={blockIndex}>
                <Col className="order-xl-1" xl="12">
                  <Card className={!block.hidden ? "background-primary shadow m-1 card-full" : "background-primary shadow m-1 card-full d-none"}>
                    <CardHeader className="background-primary border-0 pb-2 pt-2 card-header text-white">
                      <Row className="align-items-center">
                        <Col xs="10" className="p-0">
                          <h3 className="mb-0 pl-2 text-white font-weight-normal">
                            {block.title}
                          </h3>
                        </Col>
                        <Col xs="2" className="m-0 p-0 text-center">
                          <i
                            className={
                              this.state.show[block.id]
                                ? "ni ni-bold-up"
                                : "ni ni-bold-down"
                            }
                            color="white"
                          ></i>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="bg-white">
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              {
                                // FOR EACH QUESTION
                                block.questions.map(
                                  (question, index) =>
                                    !question.hidden &&
                                    (question.group ? (
                                      <>
                                        <h2 className="mb-4">
                                          {question.groupName}
                                        </h2>
                                        {question.loop && (
                                          <div>
                                            <Button
                                              className="mb-2"
                                              onClick={() => {
                                                this.addField(
                                                  block,
                                                  question,
                                                  question.loopGroup
                                                );
                                              }}
                                            >
                                              Adicionar +
                                            </Button>
                                          </div>
                                        )}
                                        <hr />
                                        {question.loopGroup &&
                                          (() => {
                                            let allQuestions =
                                              question.loopGroup.split(",");

                                            let answers = this.state.answers;

                                            let currentAnswerArray =
                                              answers[
                                                block.id + allQuestions[0]
                                              ];
                                            let loopLen = Array.isArray(
                                              currentAnswerArray
                                            )
                                              ? currentAnswerArray.length
                                              : 0;

                                            if (loopLen == 0) {
                                              return "";
                                            }

                                            let acumulator = [];

                                            for (let i = 0; i < loopLen; i++) {
                                              let allInputs = [];

                                              allInputs.push(
                                                <>
                                                  <h4 className="heading-small mb-4">
                                                    {question.groupName +
                                                      " #" +
                                                      (i + 1)}
                                                  </h4>
                                                </>
                                              );
                                              for (
                                                let j = 0;
                                                j < allQuestions.length;
                                                j++
                                              ) {
                                                let currentQuestion =
                                                  block.questions.filter(
                                                    (el) =>
                                                      el.id == allQuestions[j]
                                                  )[0];
                                                // console.log("CURRENT QUESTION ID");
                                                // console.log(allQuestions[j]);
                                                allInputs.push(
                                                  this.renderSimple(
                                                    j,
                                                    block,
                                                    currentQuestion,
                                                    i
                                                  )
                                                );
                                              }
                                              let around = (
                                                <>
                                                  <div
                                                    style={{
                                                      border: "1px #888 dashed",
                                                      padding: "20px",
                                                      marginBottom: "20px",
                                                    }}
                                                  >
                                                    {allInputs}
                                                  </div>

                                                  {question.loop &&
                                                    !question.hiddenRemove && (
                                                      <div>
                                                        <Button
                                                          className="mb-2"
                                                          onClick={() => {
                                                            let answers =
                                                              this.state
                                                                .answers;
                                                            let form =
                                                              this.state.form;
                                                            if (
                                                              question.loopGroup
                                                            ) {
                                                              for (let q of question.loopGroup.split(
                                                                ","
                                                              )) {
                                                                answers[
                                                                  block.id + q
                                                                ].splice(i, 1);
                                                                if (
                                                                  answers[
                                                                    block.id + q
                                                                  ].length == 0
                                                                ) {
                                                                  let b =
                                                                    form.blocks.filter(
                                                                      (el) =>
                                                                        el.id ==
                                                                        block.id
                                                                    )[0];
                                                                  let ques =
                                                                    b.questions.filter(
                                                                      (el) =>
                                                                        el.id ==
                                                                        q
                                                                    )[0];
                                                                  ques.hidden = true;
                                                                }
                                                              }
                                                            }

                                                            this.setState({
                                                              answers,
                                                              form,
                                                            });
                                                          }}
                                                        >
                                                          Remover
                                                        </Button>
                                                      </div>
                                                    )}
                                                </>
                                              );
                                              acumulator =
                                                acumulator.concat(around);
                                            }
                                            //console.log(acumulator);

                                            if (skipRendering.length == 0) {
                                              skipRendering = allQuestions;
                                            }

                                            return acumulator;
                                          })()}
                                      </>
                                    ) : question.endGroup ? (
                                      <>
                                        <hr />
                                      </>
                                    ) : (
                                      this.renderSimple(index, block, question)
                                    ))
                                )
                              }
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}

            <Row>
              {!this.state.isEdit && !this.state.isAudit && (
                <Col className="text-center mt-2">
                  <Button
                    color="dark"
                    className="btn-large"
                    onClick={() => {
                      this.saveForm();
                    }}
                  >
                    Salvar
                  </Button>
                </Col>
              )}
              {!this.state.isEdit && (
                <Col className="text-center mt-2">
                  <Button
                    color="dark"
                    className="btn-large"
                    onClick={() => this.validateForm()}
                  >
                    Enviar
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Template;
